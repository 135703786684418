<script>
import { required, minLength, sameAs,not } from "vuelidate/lib/validators";
import togglePassword from "@/helper/togglePassword";
import Service from "@/service/index.js"
export default {
    name: "UpdatePassword",
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            loading: false,
        };
    },
    methods: {
        togglePassword:togglePassword,
        validateState(name) {
            return this.$v[name].$dirty? (!this.$v[name].$invalid? null:false) :null
        },
        handleSubmit() {
            // stop here if form is invalid
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            this.loading= !this.loading
            new Service(this).updatePassword({
                    user_id: JSON.parse(localStorage.userdata).id,
                    old_password: this.oldPassword,
                    new_password: this.newPassword,
                    confirm_password: this.confirmPassword
                },
                /*successCallback */
                data => {
                    this.loading = false
                    this.$notify({
                        title: "SUCCESS!",
                        text: "Password updated successfully",
                        type: "success "
                    });
                },
                /* failCallback */
                ()=> {
                    this.loading = false
                }
            );
        },
    },
    validations: {
        oldPassword: { required },
        newPassword: { required, minLength: minLength(6),notSameAs:not(sameAs('oldPassword')) },
        confirmPassword: { sameAsPassword: sameAs("newPassword") }
    }
};
</script>

<template>
   <b-container class="page reset-password">
      <div class="content-wrap narrow">
         <h1 class="text-center">Update Password</h1>
         <b-form @submit.prevent="handleSubmit">
            <b-form-group label="Old Password">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="$v.oldPassword.$model"
                     :state="validateState('oldPassword')"
                     ></b-form-input>
                  <b-input-group-append>
                     <b-button type="button" class="view-password-btn" variant="outline-primary" @click="togglePassword($event.target)">
                        <i class="material-icons"  style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback
                     >Password is required</b-form-invalid-feedback>
               </b-input-group>
            </b-form-group>
            <b-form-group label="New Password">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="$v.newPassword.$model"
                     :state="validateState('newPassword')"
                     ></b-form-input>
                  <b-input-group-append>
                     <b-button type="button" class="view-password-btn" variant="outline-primary" @click="togglePassword($event.target)">
                        <i class="material-icons"  style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback
                     >Password is required, Password must have at least {{ $v.newPassword.$params.minLength.min }} characters, cannot be same as old password</b-form-invalid-feedback>
               </b-input-group>
            </b-form-group>
            <b-form-group label="Confirm Password">
               <b-input-group>
                  <b-form-input
                     type="password"
                     placeholder
                     v-model="$v.confirmPassword.$model"
                     :state="validateState('confirmPassword')"
                     />
                  <b-input-group-append>
                     <b-button type="button" class="view-password-btn" variant="outline-primary" @click="togglePassword($event.target)">
                        <i class="material-icons"  style="display:none">visibility</i>
                        <i class="material-icons" style="display:inline-block">visibility_off</i>
                     </b-button>
                  </b-input-group-append>
                  <b-form-invalid-feedback>New password and confirm password should be same</b-form-invalid-feedback>
               </b-input-group>
            </b-form-group>
            <b-form-group class="text-center">
               <b-button variant="primary" type="type" block :disabled="loading">
                   <b-spinner v-if="loading" small label="Spinning"></b-spinner>
                   Submit
                </b-button>
            </b-form-group>
         </b-form>
      </div>
   </b-container>
</template>